import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Image from '../components/image'
import Countdown from '../components/countdown'

const Container = styled.div`
  display: flex;
  min-width: 100vw;
  background-color: #7a7b73;
  color: white;
`

const Spacer = styled.div`
  flex: 1 0 auto;
`

const Content = styled.div`
  flex: 0 0 auto;
  width: 300px;
  text-align: right;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  blockquote {
    margin-right: 0;
  }
`

const IndexPage = () => (
  <Layout>
    <Container>
      <Spacer />
      <Content>
        <h1>Martin Wind</h1>
        <blockquote>There’s always a bigger fish.<br /><small>Qui-Gon Jinn</small></blockquote>
      </Content>
      <Image />
    </Container>
  </Layout>
)

export default IndexPage
